import Coverage from '@root/auto-pricing/src/models/coverage';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { RootError } from '@root-common/root-errors';

const COVERAGE_COPY_OVERRIDES = {
  CA: {
    [Coverage.Symbols.COLLISION]: Coverage.getLocaleName({
      market: 'CA',
      symbol: Coverage.Symbols.COLLISION,
    }) + ' coverage pays for repairs to your vehicle or for a replacement vehicle if you get into an accident.',
    [Coverage.Symbols.COMPREHENSIVE]: Coverage.getLocaleName({
      market: 'CA',
      symbol: Coverage.Symbols.COMPREHENSIVE,
    }) + ' coverage pays for repairs or for a replacement vehicle if your vehicle is stolen or damaged by anything other than an accident.',
    [Coverage.Symbols.BODILY_INJURY]: Coverage.getLocaleName({
      market: 'CA',
      symbol: Coverage.Symbols.BODILY_INJURY,
    }) + ' coverage pays for any injuries to others when you cause an accident.  This includes medical expenses like doctor visits and physical therapy.',
    [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: 'UM/UIM BI pays for your medical expenses if you are in an accident caused by someone without liability insurance to cover your medical bills.',
  },
  FL: {
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Covers medical expenses and lost wages for you and your dependent resident relatives, regardless of fault.',
  },
  KS: {
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Personal Injury Protection generally covers medical expenses and lost wages for you and possibly your passengers. (If you suffer injuries and you are a titled owner of an insured motor vehicle, you should notify your own insurance company because your PIP/Kansas no-fault benefits will be the first to cover medical expenses incurred by you, regardless of who is at fault.)',
  },
  VA: {
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Covers lost income if you\'re unable to work as a result of an accident.',
  },
  WI: {
    [Coverage.Symbols.UNDERINSURED_MOTORIST]: 'Covers your damages for bodily injury caused by an underinsured vehicle.',
  },
};

const coverageCopies = (market) => {
  const copies = {
    [Coverage.Symbols.BODILY_INJURY]: 'Covers medical expenses for others injured when you cause an accident.',
    [Coverage.Symbols.COLLISION]: 'Helps pay for repairs or replacement to your vehicle when you’re in an accident with another vehicle or object, regardless of who’s at fault.',
    [Coverage.Symbols.COMPREHENSIVE]: 'Helps pay for repairs or replacement to your vehicle caused by something other than a collision. This can include things like storm damage, broken windshields, vandalism, hitting a deer, and even car theft.',
    [Coverage.Symbols.FUNERAL_BENEFITS]: 'Covers lost wages for you and your passengers, regardless of fault.',
    [Coverage.Symbols.MEDICAL_EXPENSES]: 'Covers medical expenses for you and your passengers, regardless of fault.',
    [Coverage.Symbols.MEDICAL_PAY]: 'Covers medical expenses for you and your passengers, regardless of fault.',
    [Coverage.Symbols.PERSONAL_INJURY_PROTECTION]: 'Covers medical expenses and lost wages for you and your family members, regardless of fault.',
    [Coverage.Symbols.PROPERTY_DAMAGE]: 'Covers damage to other vehicles or property when you cause an accident.',
    [Coverage.Symbols.RENTAL]: 'Covers you if your vehicle is being repaired due to an accident and you need a ride.',
    [Coverage.Symbols.UNINSURED_UNDERINSURED_MOTORIST]: Coverage.getLocaleName({
      market,
      symbol: Coverage.Symbols.UNINSURED_MOTORIST,
    }) + ' covers your medical expenses when you are hit by a driver who does not have insurance. ' + Coverage.getLocaleName({
      market,
      symbol: Coverage.Symbols.UNDERINSURED_MOTORIST,
    }) + ' covers your medical expenses when you are hit by a driver who does not have high enough coverage limits to cover the cost of your injuries.',
    [Coverage.Symbols.UNINSURED_MOTORIST]: 'Covers your medical expenses when you are injured by an uninsured driver.',
    [Coverage.Symbols.UNDERINSURED_MOTORIST]: 'Covers your medical expenses when you are injured by an underinsured driver.',
    [Coverage.Symbols.UNINSURED_MOTORIST_PROPERTY_DAMAGE]: 'Covers your property damage caused by an uninsured driver.',
    [Coverage.Symbols.UNDERINSURED_MOTORIST_PROPERTY_DAMAGE]: 'Covers your property damage caused by an underinsured driver.',
    [Coverage.Symbols.WORK_LOSS]: 'Covers funeral expenses for you and your passengers, regardless of fault.',
  };

  const overrides = COVERAGE_COPY_OVERRIDES[market];

  return {
    ...copies,
    ...overrides,
  };
};

const getCopy = (market, symbol) => {
  const copies = coverageCopies(market);
  if (!Object.keys(copies).includes(symbol)) {
    throw new RootError({
      message: `Unexpected coverage symbol: ${symbol}`,
      name: 'CoverageCopyError',
      fingerprint: ['CoverageCopyUnexpectedSymbol'],
    });
  }
  return copies[symbol];
};

export default function CoverageCopy({
  coverage, market, additionalLink = null,
}) {
  if (!additionalLink) {
    return <>{getCopy(market, coverage.symbol)}</>;
  }
  return <>{getCopy(market, coverage.symbol)}{' '}{additionalLink}</>;
}

CoverageCopy.propTypes = {
  additionalLink: PropTypes.node,
  coverage: PropTypes.instanceOf(Coverage).isRequired,
  market: PropTypes.string.isRequired,
};
