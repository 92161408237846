import * as Experiences from '@root/core/src/models/experiences';
import StorageService from '@root/core/src/services/storage-service';
import environment from '@root/core/src/utils/environment';
import postAdditionalBillingFieldsExperienceConfiguration
from '@root/payments/src/api/additional_billing_fields/post-additional-billing-fields-experience-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useEffect, useState } from '@root/vendor/react';

export const ADDITIONAL_BILLING_FIELDS_COOKIE_KEY = 'additional_billing_fields_experience';

export default function useAdditionalBillingFieldsExperience(bypassExperience = false) {
  const experienceEnabled = environment.isAdditionalBillingFieldsExperienceEnabled;
  const experienceCookie = StorageService.getItem(ADDITIONAL_BILLING_FIELDS_COOKIE_KEY);
  const [experienceValue, setExperienceValue] = useState(undefined);
  const [postAdditionalBillingFieldsExperience] = useSafeImperativeNetworkRequest(postAdditionalBillingFieldsExperienceConfiguration);
  const isLoading = experienceValue === undefined;

  useEffect(() => {
    const getAdditionalBillingFieldsExperience = async () => {
      if (!experienceCookie) {
        const result = await postAdditionalBillingFieldsExperience();
        if (result && result.data && result.data.experience) {
          setExperienceValue(result.data.experience);
        } else {
          setExperienceValue(Experiences.AdditionalBillingFieldExperiences.CONTROL);
        }
      } else {
        setExperienceValue(experienceCookie);
      }
    };

    if (!experienceEnabled || bypassExperience) {
      setExperienceValue(Experiences.AdditionalBillingFieldExperiences.CONTROL);
    } else if (!experienceValue) {
      getAdditionalBillingFieldsExperience();
    }
  }, [bypassExperience, experienceCookie, experienceEnabled, experienceValue, postAdditionalBillingFieldsExperience]);

  useEffect(() => {
    if (experienceEnabled && experienceValue && !experienceCookie) {
      StorageService.setItem(ADDITIONAL_BILLING_FIELDS_COOKIE_KEY, experienceValue);
    }
  }, [experienceCookie, experienceValue, experienceEnabled]);

  return [experienceValue, isLoading];
}
