import CustomQuote from '@root/quotes/src/models/custom-quote';
import QuoteStrings from '@root/quotes/src/models/quote-strings';
import uuidv4 from '@root/vendor/uuid/v4';

export default class QuoteCustomizationService {
  static createCustomQuote(
    quotesContext,
    quoteCoveragesContext,
  ) {
    const tierPrecedence = [
      QuoteStrings.TIERS.CUSTOM,
      QuoteStrings.TIERS.RATE_CALL_1_MATCH,
      QuoteStrings.TIERS.EMBEDDED_PRIOR_COVERAGE,
      QuoteStrings.TIERS.EMBEDDED,
      QuoteStrings.TIERS.RECOMMENDED,
    ];

    const quotes = tierPrecedence
      .map((tier) => quotesContext.getQuoteByTier(tier))
      .filter((quote) => !!quote);

    return CustomQuote.build({
      ...quotes[0],
      quoteCoveragesContext,
    });
  }

  static updateCustomQuote(
    profileParams,
    selectedQuoteUpdates,
    updatedCoverageSymbol,
    quotingRules,
    quotesContext,
  ) {
    if (!selectedQuoteUpdates.originQuoteId) {
      selectedQuoteUpdates = selectedQuoteUpdates
        .set('originQuoteId', selectedQuoteUpdates.id)
        .set('id', uuidv4());
    }

    const profileVins = profileParams
      .getSelectedVehiclesWithVin()
      .map((v) => v.getAvailableVin());

    return selectedQuoteUpdates
      .fixupCoverages(quotingRules, updatedCoverageSymbol)
      .updatePrices(profileVins)
      .addTaxAndFees(quotesContext);
  }

  static getCoverageSelections(
    billingCycle,
    customQuote,
    profileParams,
    quotingRules,
    quoteCoveragesContext,
  ) {
    const market = profileParams.market();
    const profileVins = profileParams
      .getSelectedVehiclesWithVin()
      .map((v) => v.getAvailableVin());

    return quoteCoveragesContext.getCoverageSelections({
      billingCycle,
      customQuote,
      profileVins,
      quotingRules,
      market,
    });
  }

  static acceptCoverage(
    customQuote,
    coverageSymbol,
  ) {
    const coverage = customQuote.getCoverage(coverageSymbol);
    if (!coverage) { return customQuote; }

    const { coveredVins } = customQuote.getDeductibleInfo();
    coverage.coveredVins = coveredVins;

    return customQuote.acceptCoverages([coverage]);
  }
}
