import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function postAdditionalBillingFieldsExperienceConfiguration() {
  return new NetworkRequestConfiguration({
    endpoint: 'ab_tests/additional_billing_fields_experience/set_experience',
    method: NetworkRequestConfiguration.Methods.POST,
    successCodes: [200],
    bodyParameters: {},
  });
}
