export default ({
  partner: 'Partner',
  components: {
    bindQuoteCustomization: {
      coverageDetailsCard: {
        coveredDriversList: {
          addItem: 'Add driver',
          title: 'Covered drivers',
        },
        coveredVehiclesList: {
          addItem: 'Add vehicle',
          title: 'Covered vehicles',
        },
        fullTermMonthlySavingsText: {
          saveAfterPrice: '/month by paying 6 months upfront.',
          saveBeforePrice: 'Save',
          switchToFullTermLink: 'Switch to a 6 month payment',
        },
        fullTermTotalSavingsText: {
          alternativePay: 'if paid in 6 monthly payments.',
          saveAfterPrice: 'by paying 6 months up front.',
          saveBeforePrice: 'You’re saving',
        },
        localErrors: {
          missingCoveredVehicle: 'At least one vehicle must be included in your coverage',
        },
        mailingAddressListItem: {
          title: 'Home address',
        },
        submit: 'Continue to checkout',
        readOnlySubmit: 'Save updated policy',
      },
      coverageSectionRoadside: {
        description: 'Included with every policy',
      },
      coverageRideshare: {
        infoModal: {
          header: 'Rideshare & Delivery coverage',
          requirement_normal: 'Root requires this coverage if you drive for a rideshare or delivery (TNC) company like Uber or DoorDash.',
          requirement_bold: 'Without it, we may deny your claims—even claims from personal driving.',
          phase_1: {
            header: 'While you’re waiting for a trip (Phase 1)',
            bi: ({ biDisplayName, pdDisplayName }) => `${biDisplayName} and ${pdDisplayName} cover others if you cause an accident.`,
            compColl: ({ collDisplayName, compDisplayName }) => `${collDisplayName} and ${compDisplayName} cover damage to your vehicle.`,
          },
          phase_2: {
            header: 'Once you’ve accepted a trip (Phase 2 & 3)',
            compColl: ({ collDisplayName, compDisplayName }) => `${collDisplayName} and ${compDisplayName} cover damage to your vehicle.`,
          },
          good_to_know: {
            header: 'Good to know',
            bi: ({ collDisplayName, compDisplayName }) => `Your ${collDisplayName} and ${compDisplayName} deductibles will match what you chose for your personal policy.`,
            compColl: ({ biDisplayName, pdDisplayName }) => `${biDisplayName} and ${pdDisplayName} will be covered up to state minimum limits.`,
            bullet_3: 'All drivers and vehicles on your policy will be covered.',
            bullet_4: 'You can always find more information about your coverages and limits in your policy docs.',
          },
          disclaimer: '* Your rideshare or delivery company may provide additional coverage. Please contact them for more information.',
          closeButton: 'Dismiss',
        },
        removalConfirmation: {
          confirmationMessage: 'Are you sure you want to remove Rideshare & Delivery coverage?',
          description: 'Root requires Rideshare & Delivery coverage if you drive for companies like Uber and DoorDash. If you don’t have this coverage, Root may deny your claims—even if an incident occurs during personal driving.',
          confirmationButton: 'Remove coverage',
          nevermind: 'Never mind',
        },
      },
      mobileStickyPriceFooter: {
        button: 'Review',
      },
    },
    bindStartEntry: {
      coverageSummary: {
        covered: {
          driver: 'Covered driver',
          vehicle: 'Covered vehicle',
        },
      },
      dobInputGroup: {
        dateParts: {
          day: 'day',
          month: 'month',
          year: 'year',
        },
        errors: {
          invalid: ({ dateString }) => `${dateString} is not a valid date`,
          maxAge: ({ maxAge }) => `You must be ${maxAge} years or younger to sign up`,
          minAge: ({ minAge }) => `You must be ${minAge} years or older to sign up`,
          missing: ({ datePart, digits }) => `Enter your ${digits}-digit birth ${datePart}.`,
        },
        title: 'When’s your birthday?',
      },
      driverLicenseInput: {
        errors: {
          invalid: 'Enter a valid license number.',
        },
        label: 'Driver’s license number',
        title: 'What is your driver’s license info?',
      },
      footerDisclaimers: {
        header: 'We will never sell your information.',
      },
      homeownerSelect: {
        placeholder: 'Homeownership status',
        title: 'Do you own or rent your home?',
      },
      maritalStatusSelect: {
        placeholder: 'Marital status',
        title: 'What is your marital status?',
      },
      ratingMunicipalityInput: {
        errors: {
          invalid: 'We’re unable to find this city.',
        },
        label: 'Enter your city',
        title: 'What city do you live in?',
        info: 'If you live in a city or a borough that’s part of a larger city, please enter the larger city’s name.',
      },
      phoneNumberInput: {
        label: 'Phone number',
        title: 'Phone number',
      },
      welcomeBlock: {
        title: {
          line1: 'Car insurance,',
          line2: 'in less than 5 minutes',
        },
        subTitle: 'Great news. We partnered with Root to help you get coverage in a fraction of the time.',
      },
    },
    bindView: {
      header: {
        progress: {
          items: {
            checkout: 'Checkout',
            customizeCoverages: 'Customize coverages',
            finalizeQuote: 'Finalize quote',
          },
        },
      },
      backTo: 'Back to your vehicle order',
      backToProfile: 'Back to your profile',
      backToQuote: 'Cancel and return to your quote',
    },
    speakToAgent: {
      button: 'Speak to an agent',
      modal: {
        agentInfo: {
          available: {
            heading: {
              callFrom: 'Call',
              speakTo: 'to speak with a Root agent.',
            },
            detail: 'We’re available Monday-Friday 8:30 a.m.-8:30 p.m. ET.',
          },
        },
        close: 'Close',
      },
    },
    insuranceCard: {
      back: {
        partnerNamePrefix: 'to ',
        action: 'Proof of insurance sent',
      },
      front: {
        card: 'Insurance card',
      },
    },
  },
  pages: {
    bindStartEntry: {
      card: {
        header: 'We just need to confirm a few things',
        paragraph: 'Answer a few final questions below, and that’s it! On the next screen, you’ll be able to customize your quote and add any other drivers or vehicles.',
        button: 'Finalize my quote',
      },
    },
    bindCoveredEntry: {
      title: 'Nice! That was easy.',
      softLaunch: {
        checkFilledOne: 'Your car insurance is all set up.',
        checkFilledTwo: 'Next: Send your proof of insurance to Carvana',
        instructionsHeader: 'We’ve sent you an email with:',
        instructionsBulletOne: 'Your proof of insurance',
        instructionsBulletTwo: 'A link to upload your proof of insurance to Carvana',
        instructionsBulletThree: 'Info on how to manage your policy',
        instructionsBulletFour: 'How to contact Root with any questions',
      },
      v1: {
        checkFilledOne: 'Your car insurance is all set up.',
        checkFilledTwo: 'We’ve sent your proof of insurance to Carvana.',
        instructionsHeader: 'We’ve sent you an email with:',
        instructionsBulletOne: 'Your insurance card and policy docs',
        instructionsBulletTwo: 'Info on how to manage your policy',
        instructionsBulletThree: 'How to contact Root with any questions',
      },
      button: 'Return to your vehicle order',
    },
    bindDisclaimers: {
      title: 'And now for the legal stuff.',
      subtitle: 'Now that you’ve made your selections, we need to confirm that you understand a few things.',
      affirmTitle: 'Affirmation Statement and Electronic Signature',
      affirmReadDocuments: 'I verify and confirm that I have read and agree to the document(s) above, that I own or lease the vehicle(s) on this policy, and that I am the Named Insured on this policy and authorized to make selections of coverage.',
      affirmLegalSignature: 'I understand that tapping ‘Agree’ below constitutes a legal signature confirming that I acknowledge and agree to the above Affirmation Statement.',
      buttonConfirm: 'Confirm',
      buttonAgree: 'Agree',
      buttonDecline: 'Decline',
    },
    bindCheckout: {
      nextPaymentDue: 'Next Payment ',
      paymentMethodFormHeader: 'Payment method',
      reviewYourPlan: 'Review your plan and enter your payment info here.',
      frequencyAndStartDateFormHeader: 'Frequency & start date',
      paymentDisclaimer: 'By clicking “Submit payment” you authorize Root to automatically charge your payment method (or any other payment method provided to Root) each month or policy period in the amount in effect, which may include applicable fees. Your authorization continues as long as you have a policy with Root.',
      secure: 'Secure',
      startDate: {
        appendedText: '- Vehicle delivery date',
      },
    },
    bindLoading: {
      personalizing: 'Just a moment while we personalize your policy',
      loadingDetails: {
        1: {
          title: 'Hassle-free',
          detail: 'We take care of your proof of insurance for you. No phone calls, no paperwork.',
        },
        2: {
          title: 'Get more value',
          detail: 'Roadside Assistance is included in every Root policy.',
        },
        3: {
          title: 'All in an app',
          detail: 'Root customers can easily update their policies or payment info anytime with the free Root app.',
        },
        4: {
          title: 'Quick and easy claims',
          detail: 'File a claim in just 5 minutes right in the free Root app.',
        },
      },
    },
    bindQuoteCustomization: {
      hero: {
        heading: 'A policy built for you',
        content: 'You can update or add coverages to fit your needs. Or, if everything looks good, you can continue to checkout.',
        coveragesOnlyHeading: 'Customize your coverages',
      },
    },
    bindUnderwritingDeclined: {
      continueButton: 'Continue',
      heading: 'Let’s get you on the fast track to the right insurance.',
      info: 'We don’t have a great product for you yet. We’re dedicated to helping you find the best carrier for your needs while we work on building the best product for you. To help get you another quote quickly, we’d like to share some of your information with a few carriers that might be a better fit in the meantime.',
    },
    bindUnderwritingDeclinedResell: {
      heading: 'We want to help you find the best option for your needs.',
      info: 'Check out some options below',
    },
    bindWelcome: {
      message: '',
    },
    missingAuthEntry: {
      heading: 'Whoops!',
      info: 'It looks like the link you clicked has expired',
      instruction: 'Please revisit this page from',
      source: 'your vehicle purchase screen',
    },
  },
});
