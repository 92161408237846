import Coverage from '@root/auto-pricing/src/models/coverage';
import CoverageGroup from '@root/quotes/src/components/coverage-options/coverage-group';
import CoverageGroupItem from '@root/quotes/src/components/coverage-options/coverage-group-item';
import ListCard from '@root/core/src/components/list-card';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useI18nNext } from '@root/bind.joinroot.com/src/hooks/use-i18n';

export default function CoverageSectionRoadside({ market, styleOverrides }) {
  const strings = useI18nNext('components.bindQuoteCustomization.coverageSectionRoadside');

  const heading = useMemo(() => Coverage.getLocaleName({
    market,
    symbol: Coverage.Symbols.ROADSIDE,
  }), [market]);

  return (
    <CoverageGroup
      heading={heading}
      hideDivider={true}
      styleOverrides={styleOverrides}
    >
      <ListCard headerBackgroundColor={Colors.white()}>
        <CoverageGroupItem
          css={styles.roadside}
          description={strings.description}
          heading={heading}
        />
      </ListCard>
    </CoverageGroup>
  );
}

const styles = StyleSheet.create({
  roadside: {
    borderRadius: '10px 10px',
    ...Responsive.sm({
      border: `1px solid ${Colors.gray30()}`,
    }),
  },
});

CoverageSectionRoadside.propTypes = {
  market: PropTypes.string.isRequired,
  styleOverrides: PropTypes.object,
};
