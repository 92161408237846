import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const DO_NOT_SELL_INFORMATION_URL = `${environment.websiteBaseUrl}/privacy/california/`;

export function DoNotSellMyInformationLink({ styles }) {
  return (
    <a
      css={styles}
      href={DO_NOT_SELL_INFORMATION_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Do Not Sell or Share My Personal Information
    </a>
  );
}

DoNotSellMyInformationLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
